import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import logger from '@/server/config/pino-config'
const axiosInstance: AxiosInstance = axios.create({})

// eslint-disable-next-line
const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (config as any).metadata = { startTime: Date.now() }
    return config
}

// eslint-disable-next-line
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
}

// eslint-disable-next-line
const onResponse = (response: AxiosResponse): AxiosResponse => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const startTime = (response.config as any)?.metadata?.startTime
    const duration = Date.now() - startTime
    logger.error(`Response time for ${response.config.url}: ${duration}ms`)
    return response
}

// eslint-disable-next-line
const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    logger.error("In axios error interceptor")
    return Promise.reject(error)
}

// axiosInstance.interceptors.request.use(onRequest, onRequestError)
// axiosInstance.interceptors.response.use(onResponse, onResponseError)

export default axiosInstance