import { CountryCode } from "@/common/config"
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { z } from "zod"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export const getCountryDate = (countryCode: string, date: Date): Date => {
	return new Date(
		new Date(date).toLocaleString("en-US", {
			timeZone: getTimeZone(countryCode),
		})
	)
}

export const getTimeZone = (countryCode: string) => {
	const timeZone: Record<string, string> = {
		IN: "Asia/Kolkata",
		US: "America/New_York",
	}
	return timeZone[countryCode]
}
export const getDisplayTimeZone = (countryCode: string) => {
	const timeZone: Record<string, string> = {
		IN: "IST",
		US: "ET",
	}
	return timeZone[countryCode]
}

export const formatDateToDateString = (
	date: string,
	countryCode: string
): string => {
	const options: Intl.DateTimeFormatOptions = {
		timeZone: getTimeZone(countryCode.toUpperCase()),
		month: "short",
		day: "numeric",
		year: "numeric",
	}
	// const optionsForHour: Intl.DateTimeFormatOptions = {
	// 	timeZone: getTimeZone(countryCode.toUpperCase()),
	// 	hour: "2-digit",
	// 	minute: "2-digit",
	// 	hour12: false,
	// }

	const formatter = new Intl.DateTimeFormat("en-US", options)
	// const formatterForHour = new Intl.DateTimeFormat("en-US", optionsForHour)
	// const formattedHour = formatterForHour.format(new Date(date))

	const formattedDate = formatter.format(new Date(date))

	// // Get the time zone abbreviation
	// const timeZoneAbbr = getDisplayTimeZone(countryCode.toUpperCase())

	// return `${formattedDate} at ${formattedHour} ${timeZoneAbbr}`
	return `${formattedDate}`
}

/**
 * Transform the heading to a more readable format
 * default separator is space
 */
export const transformHeading = (tab: string, separator: string = " ") => {
	return tab
		.split(separator)
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ")
}

export const validateCountryCode = (countryCode: string) => {
	try {
		const validCountryCode = z.nativeEnum(CountryCode)
		validCountryCode.parse(countryCode.toUpperCase())
		return true
	} catch (error) {
		return false
	}
}

export function compareDatesIgnoreTime(date1: Date, date2: Date): boolean {
	const date1WithoutTime = new Date(date1)
	const date2WithoutTime = new Date(date2)

	// Set time components to 0
	date1WithoutTime.setHours(0, 0, 0, 0)
	date2WithoutTime.setHours(0, 0, 0, 0)

	return date1WithoutTime.getTime() === date2WithoutTime.getTime()
}

export function filterContent(content: string) {
	const regex = /<\/?(img|image|a)\b[^>]*>/gi
	return content.replace(regex, "")
}

export function slugify_array(my_array: (string | number)[]) {
	return my_array
		.filter(str => str)
		.map(
			str =>
				str
					.toString()
					.trim()
					.replace(/[^\w\s-]/g, "") // remove non alphanumeric characters, spaces, dashes
					.replace(/[\s_-]+/g, "-") // replace word separators (spaces, underscores, dashes) with a single dash
		)
		.join("-")
		.toLowerCase()
		.replace(/^-+|-+/g, "-") // replace consecutive dashes with a single dash
}

export function getPdfLink(pdfLink: string, pageNo: number) {
	return `${pdfLink}#page=${pageNo}`
}

export function extractFromData(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[] | undefined,
	type: string,
	value: string
) {
	if (!Array.isArray(data) || !Array.isArray(data[data.length - 1])) {
		return null
	}
	const innerArray = data[data.length - 1]
	for (let i = 0; i < innerArray.length; i++) {
		const item = innerArray[i]
		if (item && item.type === type) {
			return item[value] || null
		}
	}
	return null
}

export const capitalizeWords = (name: string): string => {
	return name
		.split(" ")
		.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ")
}
