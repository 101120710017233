import { CountryCode, DocumentTypesIN, DocumentTypesUS } from "@/common/config"
import { APIErrorResponse } from "@/server/common/response"

export type DocumentCollectionDbConfig = {
	collectionName: string
	searchIndex?: string
	highlightPath?: string[]
}

export type EmbeddingCollectionDbConfig = {
	collectionName: string
	vectorSearchIndex: string
	path: string
}

export type DbConfigIN = {
	[k in DocumentTypesIN | "stock_id"]: {
		document: DocumentCollectionDbConfig
		embedding?: EmbeddingCollectionDbConfig
	}
}
export type DbConfigUS = {
	[k in DocumentTypesUS | "stock_id"]: {
		document: DocumentCollectionDbConfig
		embedding?: EmbeddingCollectionDbConfig
	}
}

export const dbConfigIN: DbConfigIN = {
	[DocumentTypesIN.ANNOUNCEMENT]: {
		document: {
			collectionName: "announcements",
			searchIndex: "announcement-summary-search-index",
			highlightPath: ["summary"],
		},
	},
	[DocumentTypesIN.ANNUAL_REPORT]: {
		document: {
			collectionName: "in_annual_report_insights",
			searchIndex: "report_text_content",
			highlightPath: ["report_data.text_content"],
		},
		embedding: {
			collectionName: "in_annual_report_embeddings_3_small",
			vectorSearchIndex: "in-report-embedding-vector-index",
			path: "embedding",
		},
	},
	[DocumentTypesIN.EARNINGS_TRANSCRIPT]: {
		document: {
			collectionName: "in_earnings_transcripts",
			searchIndex: "report_text_content",
			highlightPath: [
				"report_data.text_content",
				"report_data.text_content_management",
				"report_data.text_content_participants",
				"report_data.text_content_presentation",
				"report_data.text_content_qna",
			],
		},
		embedding: {
			collectionName: "in_earnings_transcripts_embeddings",
			vectorSearchIndex: "in-earnings-transcripts-embedding-vector-index",
			path: "embedding",
		},
	},
	stock_id: {
		document: {
			collectionName: "stock_ids",
			searchIndex: "stock-details-search-index",
		},
	},
}

export const dbConfigUS: DbConfigUS = {
	[DocumentTypesUS["8-K"]]: {
		document: {
			collectionName: "us_announcements",
			searchIndex: "us-announcement-summary-search-index",
			highlightPath: ["summary"],
		},
	},
	[DocumentTypesUS["10-K"]]: {
		document: {
			collectionName: "us_annual_reports_insights_sec_api",
			searchIndex: "report_text_content",
			highlightPath: ["report_data.text_content"],
		},
		embedding: {
			collectionName: "us_annual_report_embedding_3_small",
			vectorSearchIndex: "us-report-embedding-vector-index",
			path: "embedding",
		},
	},
	[DocumentTypesUS.EARNINGS_TRANSCRIPT]: {
		document: {
			collectionName: "us_report_insights",
			searchIndex: "report_text_content",
			highlightPath: [
				"report_data.text_content",
				"report_data.text_content_management",
				"report_data.text_content_participants",
				"report_data.text_content_presentation",
				"report_data.text_content_qna",
			],
		},
		embedding: {
			collectionName: "us_earnings_transcripts_embeddings_3_small",
			vectorSearchIndex: "us-earnings-transcripts-embedding-vector-index",
			path: "embedding",
		},
	},
	stock_id: {
		document: {
			collectionName: "us_stock_ids",
			searchIndex: "us-stock-details-search-index",
		},
	}
}

export const getDbConfig = (countryCode: CountryCode) => {
	const map = {
		[CountryCode.IN]: dbConfigIN,
		[CountryCode.US]: dbConfigUS,
	}
	return map[countryCode]
}

export const getDocumentCollectionDbConfig = <T extends CountryCode>(
	countryCode: T,
	documentType: T extends CountryCode.IN
		? DocumentTypesIN | "stock_id"
		: T extends CountryCode.US
		? DocumentTypesUS | "stock_id"
		: never
) => {
	let config
	if (countryCode === CountryCode.IN) {
		config = dbConfigIN[documentType as DocumentTypesIN | "stock_id"]
	} else if (countryCode === CountryCode.US) {
		config = dbConfigUS[documentType as DocumentTypesUS | "stock_id"]
	} else {
		throw new Error(`DbConfig not found for documentType ${documentType}`)
	}
	if (!config.document) {
		throw new Error(
			`Document config not found for documentType ${documentType}`
		)
	}

	return config.document
}

export const getVectorStoreCollectionDbConfig = <T extends CountryCode>(
	countryCode: T,
	documentType: T extends CountryCode.IN
		? DocumentTypesIN | "stock_id"
		: T extends CountryCode.US
		? DocumentTypesUS | "stock_id"
		: never
) => {
	let config
	if (countryCode === CountryCode.IN) {
		config = dbConfigIN[documentType as DocumentTypesIN | "stock_id"]
	} else if (countryCode === CountryCode.US) {
		config = dbConfigUS[documentType as DocumentTypesUS | "stock_id"]
	} else {
		throw APIErrorResponse.forError({
			status: "400",
			title: `${countryCode} not supported`,
		})
		// throw new Error(`DbConfig not found for documentType ${documentType}`)
	}
	if (!config.embedding) {
		throw APIErrorResponse.forError({
			status: "400",
			title: `${documentType} not supported`,
		})
		// throw new Error(
		// 	`Embedding config not found for documentType ${documentType}`
		// )
	}

	return config.embedding
}

export const UserAccountsDbConfig = {
	collectionName: "user_accounts",
}


export const WatchlistDbConfig = {
	collectionName: "watchlists"
}

export const ConversationsDbConfig = {
	collectionName: "conversations"
}