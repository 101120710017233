import { APIErrorJsonResponse } from "@/common/api-types/error-response"
import { Meta } from "./types"

export class PaginatedAPIResponse<T> {
	status: string
	data: T[]
	total_count?: number
	page: number
	limit: number
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	additional_data: any // Hack to allow additional data to be sent along with the response (Eg: Counts in Announcement API response)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	constructor(data: T[], page: number, limit: number, total_count?: number, others?: Record<string, any>) {
		this.status = "success"
		this.data = data
		this.total_count = total_count
		this.page = page
		this.limit = limit
		this.additional_data = others
	}
	public toJSON() {
		return {
			status: this.status,
			data: this.data,
			meta: {
				total_count: this.total_count,
				page: this.page,
				limit: this.limit,
			},
			...this.additional_data
		}
	}
}

export type APIResponseJSON<T> = {
	status: string
	data: T
	meta?: Meta
}

export class APIResponse<T> {
	status: string
	data: T
	meta?: Meta
	constructor(data: T, status: "success" | "fail" = "success", meta?: Meta) {
		this.status = status
		this.data = data
		this.meta = meta
	}
	public toJSON(): APIResponseJSON<T> {
		return {
			status: this.status,
			data: this.data,
			meta: this.meta,
		}
	}
}

export type ApiError = { status: string; title: string; message?:string}

export class APIErrorResponse {
	errors: ApiError[]
	constructor(errors: ApiError[]) {
		this.errors = errors
	}
	public static forError = (error: ApiError) => {
		return new APIErrorResponse([error])
	}
	public toJSON(): APIErrorJsonResponse {
		return {
			errors: this.errors,
		}
	}
}

export enum DefaultError {
	INTERNAL_SERVER_ERROR,
	INVALID_REQUEST,
	INVALID_DOCUMENT_TYPE,
	UNAUTHORIZED,
	UNAUTHORIZED_WATCHLIST,
	USER_NOT_EXISTS,
}

export const defaultApiErrors: Record<DefaultError, ApiError> = {
	[DefaultError.INTERNAL_SERVER_ERROR]: {
		status: "500",
		title: "Internal Server Error",
	},
	[DefaultError.INVALID_REQUEST]: {
		status: "400",
		title: "Invalid Request Parameters",
	},
	[DefaultError.INVALID_DOCUMENT_TYPE]: {
		status: "400",
		title: "Invalid document type",
	},
	[DefaultError.UNAUTHORIZED]: {
		status: "401",
		title: "User Not Authorized",
	},
	[DefaultError.UNAUTHORIZED_WATCHLIST]: {
		status: "401",
		title: "User Not Authorized to view watchlist",
	},
	[DefaultError.USER_NOT_EXISTS]: {
		status: "401",
		title: "User does not exist",
	},
}

export const defaultErrorResponses: Record<DefaultError, APIErrorResponse> = {
	[DefaultError.INTERNAL_SERVER_ERROR]: APIErrorResponse.forError({
		status: "500",
		title: "Internal Server Error",
	}),
	[DefaultError.INVALID_REQUEST]: APIErrorResponse.forError({
		status: "400",
		title: "Invalid Request Parameters",
	}),
	[DefaultError.INVALID_DOCUMENT_TYPE]: APIErrorResponse.forError({
		status: "400",
		title: "Invalid document type",
	}),
	[DefaultError.UNAUTHORIZED]: APIErrorResponse.forError({
		status: "401",
		title: "User Not Authorized",
	}),
	[DefaultError.UNAUTHORIZED_WATCHLIST]: APIErrorResponse.forError({
		status: "401",
		title: "User Not Authorized to view watchlist",
	}),
	[DefaultError.USER_NOT_EXISTS]: APIErrorResponse.forError({
		status: "400",
		title: "User does not exist",
	}),
}
