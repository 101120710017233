import { CountryCode } from "@/common/config"
import { DbConfigIN, DbConfigUS, dbConfigIN, dbConfigUS } from "./db.config"

export class StockExchangeConfig {
	exchanges: string[]
	constructor(exchanges: string[]) {
		this.exchanges = exchanges
	}
}

export interface AnnouncementTypeMap {
	[type_id: string]: {
		[type_name: string]: string[]
	}
}

export class AnnouncementConfig {
	typeMap: AnnouncementTypeMap
	constructor(typeMap: AnnouncementTypeMap) {
		this.typeMap = typeMap
	}
}

export class CountryConfig {
	countryCode: string
	name: string
	timezone: string
	stockExchangeConfig: StockExchangeConfig
	announcementConfig: AnnouncementConfig
	dbConfig: DbConfigIN | DbConfigUS

	constructor(
		countryCode: string,
		name: string,
		timezone: string,
		stockExchangeConfig: StockExchangeConfig,
		announcementConfig: AnnouncementConfig,
		dbConfig: DbConfigIN | DbConfigUS
	) {
		this.countryCode = countryCode
		this.name = name
		this.timezone = timezone
		this.stockExchangeConfig = stockExchangeConfig
		this.announcementConfig = announcementConfig
		this.dbConfig = dbConfig
	}
}

const usStockConfig = new StockExchangeConfig(["NYSE", "NASDAQ"])
const inStockConfig = new StockExchangeConfig(["BSE", "NSE"])

export const defaultAnnouncementTypeMap: AnnouncementTypeMap = {
	"1": {
		"Company Mergers": [
			"Merger of Equals",
			"Asset Acquisitions",
			"Reverse Merger",
			"Stock Swaps",
			"Leveraged Buyouts",
			"Tender Offers",
			"Joint Ventures",
			"Spin-offs",
		],
	},
	"2": {
		"Disposals and divestitures": [
			"Divestment of Assets",
			"Asset Sales",
			"Spin-offs",
			"Tender Offers",
			"Equity Carve-outs",
			"Liquidations",
		],
	},
	"3": {
		"Business Restructuring": [
			"Rights Offerings",
			"Asset Sale",
			"Debt Restructuring",
			"Management Changes",
			"Merger of Equals",
			"Spin-offs",
		],
	},
	"4": {
		"Expansion Plans": [
			"Geographic Expansion",
			"Market Expansion",
			"Product Expansion",
			"Joint Ventures",
			"Strategic Alliances",
		],
	},
	"5": {
		"Financial Troubles": [
			"Chapter 11 Bankruptcy",
			"Voluntary Bankruptcy",
			"Liquidation",
			"Debt Restructuring",
		],
	},
	"6": {
		"Management Changes": [
			"Appointments",
			"Resignations",
			"Dismissals",
			"Promotions",
			"Reorganizations",
			"Retirement",
		],
	},
	"7": {
		"Capital Structure Changes": [
			"Stock Splits",
			"Stock Buybacks",
			"Rights Offerings",
			"Debt-Equity Swaps",
			"Equity Offerings",
		],
	},
	"8": {
		"Contract Awards": [
			"Contract Renewal",
			"New Contract",
			"Contract Cancelled",
		],
	},
	"9": {
		"Legal Disputes": [
			"Civil",
			"Criminal Litigations",
			"Regulatory Compliance",
			"Arbitration",
			"Mediation",
		],
	},
	"10": {
		"Payment Defaults": ["Loan Defaults (Secured, Unsecured)", "Bond Defaults"],
	},
	"11": {
		"Credit Rating Changes": ["Upgrades", "Downgrades", "Outlook Changes"],
	},
	"12": {
		"Product Launches": [
			"New Product Launches",
			"Product Updates",
			"Product Discontinuation",
		],
	},
	"13": {
		"Operational Disruptions": [
			"Floods",
			"Fires",
			"Earthquakes",
			"Power Outages",
			"Accidents",
			"Labor Disputes",
		],
	},
	"14": {
		"Accounting Changes": [
			"Changes in Revenue Recognition",
			"Cost Recognition",
			"Asset Valuation",
			"Tax Accounting",
		],
	},
	"15": {
		"Investments/Divestments": [
			"Equity",
			"Debt",
			"VC",
			"Private Equity Investments",
			"Joint Ventures",
			"Disinvestments",
		],
	},
	"16": {
		"Dividend Policy Changes": [
			"Increase",
			"Decrease in Dividend Rate",
			"Suspension",
			"Resumption of Dividend Payments",
		],
	},
	"17": {
		"Labor Issues": [
			"Strikes",
			"Collective Bargaining",
			"Unionization",
			"Employee Benefits",
		],
	},
	"18": {
		"Investor Conferences": [
			"Investor Meetings",
			"Analyst Calls",
			"Webcasts",
			"Presentations",
		],
	},
	"19": {
		"Earnings Reports": [
			"Financial Statements",
			"Earnings Releases",
			"Earnings Guidance",
			"Cash Flow Statements",
		],
	},
	"20": {
		"Delisting Actions": [
			"Voluntary Delisting",
			"Forced Delisting",
			"Delisting due to Non-Compliance",
		],
	},
	"21": {
		"IPO Launches": [
			"Initial Public Offerings",
			"Follow-on Offerings",
			"Rights Offerings",
		],
	},
	"22": {
		"Name Changes": ["Rebranding", "Name Change"],
	},
	"23": {
		"Offer for Sale": [
			"Primary Offerings",
			"Secondary Offerings",
			"Rights Offerings",
		],
	},
	"24": {
		"US FDA Inspections": [
			"Pre-Market Approval",
			"Post-Market Surveillance",
			"Clinical Trials",
		],
	},
	"25": {
		"Earnings Calls": [
			"Earnings Guidance",
			"Analysts Questions",
			"Market Updates",
			"Financial Updates",
		],
	},
	"26": {
		"Other Situations": [],
	},
}

const defaultAnnouncementConfig = new AnnouncementConfig(
	defaultAnnouncementTypeMap
)

export const countryConfig = new Map<CountryCode, CountryConfig>([
	[
		CountryCode.US,
		new CountryConfig(
			"US",
			"United States",
			"America/New_York",
			usStockConfig,
			defaultAnnouncementConfig,
			dbConfigUS
		),
	],
	[
		CountryCode.IN,
		new CountryConfig(
			"IN",
			"India",
			"Asia/Kolkata",
			inStockConfig,
			defaultAnnouncementConfig,
			dbConfigIN
		),
	],
])

export const announcementConfig = new Map<CountryCode, AnnouncementConfig>([
	[
		CountryCode.US,
		new AnnouncementConfig({
			"1": {
				"8k": ["nothings"],
			},
		}),
	],
	[
		CountryCode.IN,
		new AnnouncementConfig({
			"1": {
				"Company Mergers": [
					"Merger of Equals",
					"Asset Acquisitions",
					"Reverse Merger",
					"Stock Swaps",
					"Leveraged Buyouts",
					"Tender Offers",
					"Joint Ventures",
					"Spin-offs",
				],
			},
			"2": {
				"Disposals and divestitures": [
					"Divestment of Assets",
					"Asset Sales",
					"Spin-offs",
					"Tender Offers",
					"Equity Carve-outs",
					"Liquidations",
				],
			},
			"3": {
				"Business Restructuring": [
					"Rights Offerings",
					"Asset Sale",
					"Debt Restructuring",
					"Management Changes",
					"Merger of Equals",
					"Spin-offs",
				],
			},
		}),
	],
])

export const tooltipInfo = {
	"ai-assist": "Answers sourced from filings since 2022",
	filings: {
		[CountryCode.US]: "Filings are sourced from EDGAR",
		[CountryCode.IN]:
			"Filings are available based on company submissions to BSE, NSE",
	},
}
