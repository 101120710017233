import { z } from "zod"

export enum CountryCode {
  US = "US",
  IN = "IN",
}
export const countryCodeSchema = z.nativeEnum(CountryCode)

export enum DocumentTypesIN {
  EARNINGS_TRANSCRIPT = 'earnings-transcript',
  ANNUAL_REPORT = 'annual-report',
  ANNOUNCEMENT = 'announcement',
  // INVESTOR_PRESENTATION="investor_presentation"
}



export enum DocumentTypesUS {
  EARNINGS_TRANSCRIPT = 'earnings-transcript',
  '10-K' = '10-K',
  '8-K' = '8-K',
  // "10-Q" = "10-Q", // investor-presentation not availble now
}

export const documentTypesIN = z.nativeEnum(DocumentTypesIN)
export const documentTypesUS = z.nativeEnum(DocumentTypesUS)

export const documentTypeMap = {
  [CountryCode.IN]: documentTypesIN,
  [CountryCode.US]: documentTypesUS
}

export enum InStockExchange {
  NSE = "NSE",
  BSE = "BSE",
}
